import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
	Box,
	Button,
	Divider,
	Grid,
	LinearProgress,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from '@mui/material';
import { PaginatedStandings, ProblemAttempt, StandingsItem } from '../../../core/standings/types';
import StandingsRow from './StandingsRow';
import { problemHeaderStyle } from './styles';
import moment from 'moment';
import ContestHeading from '../../common/ContestHeading';
import { useSnackbar } from 'notistack';
import { getFrozenCatchyMessages, getInternalStandings, getPublicStandings } from '../../../core/standings/services';
import { useParams } from 'react-router-dom';
import { getContestData } from '../../../core/contests/services';
import { ContestRead } from '../../../core/contests/types';
import ContestNotStartedPage from '../ContestNotStartedPage';
import { LinearLoader } from '../../../design-library/LinearLoader';
import { useTitle } from '../../../contexts/TitleContext';
import { motion } from 'framer-motion';
import IOSSwitch from '../../common/IOSSwitch';
import { CONTESTS_NAMESPACE } from '../../../core/store/constants';

export default function ContestStandings({ internal = false }: { internal?: boolean }) {
	const { setTitle } = useTitle();
	setTitle((internal ? 'Internal ' : '') + 'Standings');
	const getStandingsFunction = internal ? getInternalStandings : getPublicStandings;
	const PAGE_SIZE = 200;
	const { enqueueSnackbar } = useSnackbar();
	const { contestSlug } = useParams();
	const [contest, setContest] = useState<ContestRead | undefined>(undefined);
	const [isFetchingContest, setIsFetchingContest] = useState(true);

	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [standingsItems, setStandingsItems] = useState<StandingsItem[]>([]);
	const [problemsAttempts, setProblemsAttempts] = useState<ProblemAttempt[]>([]);
	const [currentUserRank, setCurrentUserRank] = useState<StandingsItem | null>(null);
	const rowRef = useRef<HTMLTableRowElement>(null);
	const [shouldAutoReload, setShouldAutoReload] = useState<boolean>(false);
	const [autoReloadMs, setAutoReloadMs] = useState(15000);

	const localStorageAnnouncementAutoReload = `${CONTESTS_NAMESPACE}/${contestSlug}/announcement/auto-reload`;

	useEffect(() => {
		const auto_reload = localStorage.getItem(localStorageAnnouncementAutoReload);
		setShouldAutoReload(auto_reload === 'true');
	}, []);

	const toggleAutoReload = (checked: boolean) => {
		setShouldAutoReload(checked);
		localStorage.setItem(localStorageAnnouncementAutoReload, checked ? 'true' : 'false');
	};

	useEffect(() => {
		const fetchContest = async () => {
			setIsFetchingContest(true);
			const contestData = await getContestData(contestSlug!);
			setContest(contestData);
			setIsFetchingContest(false);
		};
		fetchContest();
	}, []);

	useEffect(() => {
		if (!contest || !page) return;
		fetchStandings(page);
	}, [contest, page]);

	useEffect(() => {
		if (!contest || !page) return;
		const intervalMs = ['JUDGE', 'STAFF'].includes(contest.role) ? 10000 : 15000;
		setAutoReloadMs(intervalMs);
		const intervalIdRef = { current: null as NodeJS.Timeout | null };

		const fetchAndSetInterval = () => {
			intervalIdRef.current = setInterval(async () => {
				await fetchStandings(page);
			}, intervalMs);
		};

		if (shouldAutoReload) {
			fetchAndSetInterval();
		} else if (intervalIdRef.current) {
			clearInterval(intervalIdRef.current);
			intervalIdRef.current = null;
		}

		return () => {
			if (intervalIdRef.current) {
				clearInterval(intervalIdRef.current);
			}
		};
	}, [shouldAutoReload, contest, page]);

	if (isFetchingContest || !contest) return <LinearLoader />;

	const onPageChangeHandler = (event: React.ChangeEvent<unknown>, value: number) => {
		setPage(value);
		fetchStandings(value);
	};

	const fetchStandings = async (page: number) => {
		setIsLoading(true);
		getStandingsFunction(contest!.id!, page)
			.then((response) => {
				setTotalPages(response.total_pages);
				setProblemsAttempts(response.problem_attempts);
				updateFirstSolves(response);
				if (response.current_user_rank) {
					setCurrentUserRank(response.current_user_rank);
				}
			})
			.catch((error) => {
				enqueueSnackbar('Error loading standings!', {
					autoHideDuration: 3000,
					variant: 'error',
				});
				console.error(error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const updateFirstSolves = (standings: PaginatedStandings) => {
		const { problem_attempts: attempts, results: items } = standings;

		const updatedItems = items.map((item) => {
			const updatedProblemList = item.problem_list.map((problem) => {
				const matchingAttempt = attempts.find((attempt) => attempt.id === problem.id);
				if (matchingAttempt && matchingAttempt.first_solved_by_id === item.id) {
					return { ...problem, is_first_solve: true };
				}
				return problem;
			});

			return { ...item, problem_list: updatedProblemList };
		});

		setStandingsItems(updatedItems);
	};

	const hasEditAccess = ['STAFF', 'JUDGE'].includes(contest?.role ?? '');
	const contestNotStarted = moment(contest?.starts_at).isAfter(moment());
	if (contestNotStarted && !hasEditAccess) return <ContestNotStartedPage contest={contest} />;

	const startRank = ((page ?? 1) - 1) * PAGE_SIZE + 1;
	const endRank = (page ?? 1) * PAGE_SIZE;

	const actualRank = currentUserRank?.rank;
	const isCurrentUserBeforeCurrentPage = actualRank && actualRank < startRank;
	const isCurrentUserAfterCurrentPage = actualRank && actualRank > endRank;

	const currentUserRow =
		isCurrentUserBeforeCurrentPage || isCurrentUserAfterCurrentPage ? (
			<motion.div
				key={currentUserRank.id}
				ref={rowRef}
				style={{
					backgroundColor: 'rgba(167, 196, 188, 0.4)',
				}}
				whileHover={{ backgroundColor: '#f5f5f5' }}
			>
				<StandingsRow offset={0} index={actualRank - 1} data={currentUserRank} />
			</motion.div>
		) : null;

	const handleScrollToRank = () => {
		rowRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	return (
		<Grid container direction={'column'} xs={12} justifyContent={'center'} sx={{ padding: '24px' }} spacing={2}>
			<Grid item>
				<ContestHeading
					name={contest?.title ?? ''}
					starts_at={moment(contest?.starts_at)}
					stops_at={moment(contest?.ends_at)}
				/>
			</Grid>
			<Grid item container direction={'row'} xs={12} justifyContent={'center'} alignItems={'center'}>
				<Grid item xs={3} />
				<Grid item xs={6}>
					<Typography color={'secondary'} variant={'body1'} align={'center'}>
						{internal
							? 'You are watching the internal latest standings!'
							: contest?.is_frozen
							? 'The standings is currently frozen!'
							: 'You are watching the public standings!'}
					</Typography>
				</Grid>
				<Grid item container xs={3} direction={'row'} justifyContent={'end'}>
					<Tooltip title={`Auto reload standings every ${autoReloadMs / 1000} seconds`} placement='top'>
						<Typography
							color={shouldAutoReload ? 'primary' : 'secondary'}
							variant={'body1'}
							sx={{ marginRight: '8px' }}
						>
							Auto reload
						</Typography>
					</Tooltip>
					<IOSSwitch checked={shouldAutoReload} onChange={(e) => toggleAutoReload(e.target.checked)} />
				</Grid>
				{!internal && contest?.is_frozen && (
					<Grid item xs={12}>
						<Typography color={'secondary'} variant={'subtitle1'} align={'center'}>
							{getFrozenCatchyMessages()}
						</Typography>
					</Grid>
				)}
			</Grid>
			{standingsItems?.length > 0 && (
				<Grid item container xs={12} direction='column' justifyContent='center' alignItems='center'>
					<Pagination
						page={page}
						count={totalPages}
						variant='outlined'
						shape='rounded'
						size='large'
						onChange={onPageChangeHandler}
						boundaryCount={1}
					/>
				</Grid>
			)}
			{isLoading && (
				<Box sx={{ width: '100%', paddingX: '20px', marginTop: '20px' }}>
					<LinearProgress />
				</Box>
			)}
			<Grid item>
				<TableContainer>
					<Table>
						<TableHead sx={{ backgroundColor: '#f8f6f6' }}>
							<TableRow>
								<TableCell style={{ width: '50px' }} align={'center'}>
									<strong>#</strong>
								</TableCell>
								<TableCell sx={{ minWidth: '300px' }}>
									<Grid container direction='row' justifyContent='space-between' alignItems='center'>
										<Grid item>
											<strong>Name</strong>
										</Grid>
										{actualRank && (
											<Grid item>
												<Button size='small' variant='outlined' onClick={handleScrollToRank}>
													Jump to my rank
												</Button>
											</Grid>
										)}
									</Grid>
								</TableCell>
								<TableCell style={problemHeaderStyle} />
								{problemsAttempts.map((problem, index) => (
									<TableCell key={index} align={'center'} style={problemHeaderStyle}>
										<Link
											to={`/contests/${contestSlug}/problems/${problem.number_to_alpha}`}
											style={{ textDecoration: 'none', color: 'inherit' }}
										>
											<div>
												<strong>{problem.number_to_alpha}</strong>
											</div>
										</Link>
										<Divider />
										<div style={{ fontSize: '12px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'clip' }}>
											<strong style={{ display: 'inline-block', maxWidth: '100%' }}>
												{`${problem.total_accepted} / ${problem.total_tries}`}
											</strong>
										</div>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{isCurrentUserBeforeCurrentPage && currentUserRow}

							{standingsItems.map((item, index) => {
								const isCurrentUser = ((page ?? 1) - 1) * PAGE_SIZE + index + 1 === currentUserRank?.rank;
								return (
									<motion.tr
										key={item.id}
										ref={isCurrentUser ? rowRef : undefined}
										style={{
											backgroundColor: isCurrentUser ? 'rgba(167, 196, 188, 0.4)' : 'inherit',
											position: 'relative',
										}}
										whileHover={{ backgroundColor: '#f5f5f5' }}
										layout={true}
										initial={{ opacity: 0 }}
										animate={{ opacity: 1 }}
										exit={{ opacity: 0 }}
										transition={{
											type: 'spring',
											stiffness: 300,
											damping: 100,
										}}
									>
										<StandingsRow offset={((page ?? 1) - 1) * PAGE_SIZE} index={index} data={item} />
									</motion.tr>
								);
							})}

							{isCurrentUserAfterCurrentPage && currentUserRow}
						</TableBody>
					</Table>
				</TableContainer>
			</Grid>
			<Grid item container xs={12} direction='column' justifyContent='center' alignItems='center'>
				<Pagination
					page={page}
					count={totalPages}
					variant='outlined'
					shape='rounded'
					size='large'
					onChange={onPageChangeHandler}
					boundaryCount={1}
				/>
			</Grid>
		</Grid>
	);
}
